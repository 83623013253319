import React from "react";
import Collage from "../components/Collage";
import Layout from "../components/Layout";

const Events = () => {
  return (
    <Layout>
      <Collage />
    </Layout>
  );
};

export default Events;
